import {SpotlightWaitlistConstants} from '../../../helpers/Constants';
import {parseQueryStringFromURL} from '../../../helpers/Anchor';

// FORM URL
export const baseUrl = process.env.GCS_URL + '/' + (process.env.GCS_VERSION ? process.env.GCS_VERSION : '');
export const campaignUrl = baseUrl + process.env.GCS_CAMPAIGN_ENDPOINT
        + '?' + SpotlightWaitlistConstants.gcsCampaignParam + '=' + process.env.GCS_IO_CAMPAIGN_ID
        + '&' + SpotlightWaitlistConstants.gcsLocaleParam + '=' + process.env.GCS_GET_CAMPAIGN_LOCALE;
export const registrationUrl = baseUrl + process.env.GCS_REGISTRATION_ENDPOINT;
export const sendMessageUrl = process.env.GCS_SEND_EMAIL_ENDPOINT ? process.env.GCS_URL + process.env.GCS_SEND_EMAIL_ENDPOINT : '';
export const validateUserUrl = process.env.GCS_POST_VALIDATE_USER_ENDPOINT ? baseUrl + process.env.GCS_POST_VALIDATE_USER_ENDPOINT : '';
export const surveyUrl = process.env.GCS_QUESTIONNAIRE_ENDPOINT ? baseUrl + process.env.GCS_QUESTIONNAIRE_ENDPOINT
        + '?' + SpotlightWaitlistConstants.gcsLocaleParam + '=' + getFormattedLocale()
        + '&' + SpotlightWaitlistConstants.gcsCampaignParam + '=' + process.env.GCS_IO_CAMPAIGN_ID : '';
export const submitionSurveyUrl = process.env.GCS_SURVEY_RESPONSE_ENDPOINT ? baseUrl + process.env.GCS_SURVEY_RESPONSE_ENDPOINT : '';
export const campaignUrlSpotlight = baseUrl + process.env.GCS_CAMPAIGN_ENDPOINT
        + '?' + SpotlightWaitlistConstants.gcsCampaignParam + '=' + process.env.GCS_IO_CAMPAIGN_ID_SPOTLIGHT
        + '&' + SpotlightWaitlistConstants.gcsLocaleParam + '=' + process.env.GCS_GET_CAMPAIGN_LOCALE;
export const campaignUrlPostPurchase = baseUrl + process.env.GCS_CAMPAIGN_ENDPOINT
        + '?' + SpotlightWaitlistConstants.gcsCampaignParam + '=' + process.env.GCS_POST_PURCHASE_CAMPAIGN_ID
        + '&' + SpotlightWaitlistConstants.gcsLocaleParam + '=' + process.env.GCS_GET_CAMPAIGN_LOCALE;
export const voucherCount = baseUrl + process.env.GCS_VOUCHER_COUNT_ENDPOINT 
        + '?' + SpotlightWaitlistConstants.gcsCampaignParam + '=' + process.env.GCS_POST_PURCHASE_CAMPAIGN_ID
        + '&' + SpotlightWaitlistConstants.gcsLocaleParam + '=' + process.env.GCS_GET_CAMPAIGN_LOCALE;

// FORM HEADERS
export function generateHeader(apiKey) {
    let header = new Headers();
    header.append(SpotlightWaitlistConstants.contentType, SpotlightWaitlistConstants.json);
    header.append(SpotlightWaitlistConstants.accessToken, apiKey);

    return header;
}
export const campaignHeader = generateHeader(process.env.GCS_ACCESS_TOKEN);
export const voucherCountHeader = generateHeader(process.env.GCS_ACCESS_TOKEN);
export const registrationHeader = generateHeader(process.env.GCS_ACCESS_TOKEN);
export const sendHeader = generateHeader(process.env.GCS_SEND_ACCESS_TOKEN);
export const validateUserHeader =generateHeader(process.env.GCS_ACCESS_TOKEN);
export const surveyHeader = generateHeader(process.env.GCS_ACCESS_TOKEN);

// FORM BODY
export function getValidateUserBody(optInId) {
    let body = {};

    const params = parseQueryStringFromURL(window.location.search);
    body['CampaignId'] = Number(process.env.GCS_IO_CAMPAIGN_ID);
    body['locale'] = getFormattedLocale(undefined);
    body['verification_code'] = (params['verification_code']) ? params['verification_code'] : '';
    body['optins'] = optInId + ':1';

    return body;
}

export function formatUSRegistrationBody(firstname, email, locale, optinId) {
    let body = {};
    body.campaign = {
        'campaignId': Number(process.env.GCS_IO_CAMPAIGN_ID),
        'locale': getFormattedLocale(locale)
    };

    body.consumer = {'firstName': firstname, 'emailAddress': email};
    body.address = [];
    body.order = {'optIns': optinId + ':1'};

    return body;
}

export function formatPostPurchaseRegistrationBody(email, locale, tAndCOptInId, emailOptInId, hasAcceptedEmailMarketing) {
    const formattedOptinId =  `${tAndCOptInId}:1|${emailOptInId}:${hasAcceptedEmailMarketing ? '1' : '0'}`;
    let body = {};
    body.campaign = {
        'campaignId': Number(process.env.GCS_POST_PURCHASE_CAMPAIGN_ID),
        'locale': getFormattedLocale(locale)
    };

    body.consumer = {'emailAddress': email};
    body.order = {'optIns': formattedOptinId};

    return body;
}

export function formatEURegistrationBody(firstname, email, locale, addressTypeCode, countryId, isoCountryCode, optInId, hasAcceptedTermsAndConditions, isInterstitialPopup,termsandConditionsID,emailOptinID, emailMarketOptInStatus, emailMarketingoptInId, 
    firstTraitValue, secondTraitValue, surveyResponse) {
    const formattedOptinId =  (hasAcceptedTermsAndConditions) ? optInId?.toString() + ':1' : optInId?.toString() + ':0';
   const formatoptSpotlight = (hasAcceptedTermsAndConditions) ? emailOptinID?.toString() + ':1' : emailOptinID?.toString() + ':0';
    const formattedEmailOptinId = (emailMarketOptInStatus) ? emailMarketingoptInId?.toString()+':1' :  + emailMarketingoptInId?.toString()+':0'; 
   let body = {};
    body.campaign = {
        'campaignId': Number(isInterstitialPopup? process.env.GCS_IO_CAMPAIGN_ID: process.env.GCS_IO_CAMPAIGN_ID_SPOTLIGHT ),
        'locale': getFormattedLocale(locale)
    };

    body.consumer = {'firstName': firstname, 'emailAddress': email};

    if(addressTypeCode && countryId && isoCountryCode) {
        body.address = [{
            'addressTypeCode': addressTypeCode,
            'countryId': countryId,
            'countryISOCode': isoCountryCode
        }];
    } else if (isoCountryCode) {
        body.address = [{
            'addressTypeCode': 'SHIPPING_ADDRESS',
            'countryISOCode': isoCountryCode
        }];
    }

    body.order = {'optIns': isInterstitialPopup? formattedOptinId+'|'+formattedEmailOptinId : termsandConditionsID+formatoptSpotlight,
    'surveyId': Number(process.env.GCS_ORDER_SURVEY_ID),
    'referrerUrl': SpotlightWaitlistConstants.referrerUrl};
    body.Traits = [{
        'id': Number(process.env.GCS_FOOTER_FIRST_TRAIT_ID),
        "value": firstTraitValue
    },{
        'id': Number(process.env.GCS_FOOTER_SECOND_TRAIT_ID),
        "value": secondTraitValue
    }]
    body.surveyResponse = [{
        'questionId': Number(process.env.GCS_SURVEY_RESPONSE_QUESTION_ID),
        'answer': surveyResponse
    }];
    return body;
}

export function formatConstestMessageBody(firstname, email, locale) {
    let body = {};

    body.email = email;
    body.parameters = [];

    let emailParam = {};
    emailParam.name = 'email';
    emailParam.value = email;
    body.parameters.push(emailParam);

    if(firstname) {
        let firstnameParam = {};
        firstnameParam.name = 'fname';
        firstnameParam.value = firstname;
        body.parameters.push(firstnameParam);
    }

    body.campaignId = Number(process.env.GCS_IO_CAMPAIGN_ID);
    body.templateType = process.env.GCS_MESSAGE_TEMPLATE;
    body.locale = getFormattedLocale(locale);
    body.system = SpotlightWaitlistConstants.system;

    return body;
}

export function formatMessageBody(email) {
    let body = {};

    body.campaignId = Number(process.env.GCS_IO_CAMPAIGN_ID);
    body.system = SpotlightWaitlistConstants.system;
    body.email = email;
    body.template_id = Number(process.env.GCS_MESSAGE_TEMPLATE);
    body.accepts_marketing = true;
    body.merge_fields = {};

    return body;
}

export function formatSurveyBody(locale, surveyId, orderId, etbQuestionId, hasEtb, sourceQuestionId, motivationQuestionId, motivation) {
    let body = {};

    body.campaignId = Number(process.env.GCS_IO_CAMPAIGN_ID);
    body.locale = getFormattedLocale(locale);

    body.surveyId = Number(surveyId);
    body.orderId = orderId;
    body.surveyResponse = [];

    if(etbQuestionId) {
        let etbAnswer = {};
        etbAnswer.questionId = Number(etbQuestionId);
        etbAnswer.answer = (hasEtb) ? 'true' : 'false';
        body.surveyResponse.push(etbAnswer);
    }

    if(sourceQuestionId) {
        let sourceAnswer = {};
        sourceAnswer.questionId = Number(sourceQuestionId);
        const params = parseQueryStringFromURL(window.location.search);
        sourceAnswer.answer = (params['utm_source']) ? params['utm_source'] : '' ;
        body.surveyResponse.push(sourceAnswer);
    }

    if(motivationQuestionId) {
        let motivationAnswer = {};
        motivationAnswer.questionId = Number(motivationQuestionId);
        motivationAnswer.answer = (motivation) ? motivation : '';
        body.surveyResponse.push(motivationAnswer);
    }

    return body;
}

export function formatWaitlistSurveyBody(locale, surveyId, orderId, sourceQuestionId) {
    let body = {};

    body.campaignId = Number(process.env.GCS_IO_CAMPAIGN_ID);
    body.locale = getFormattedLocale(locale);

    body.surveyId = Number(surveyId);
    body.orderId = orderId;

    let sourceAnswer = {};
    sourceAnswer.questionId = Number(sourceQuestionId);
    const params = parseQueryStringFromURL(window.location.search);
    sourceAnswer.answer = (params['utm_source']) ? params['utm_source'] : '' ;

    body.surveyResponse = [];
    body.surveyResponse.push(sourceAnswer);

    return body;
}

export function formatWaitlistTopicBody(locale, surveyId, orderId, sourceQuestionId, selectedOptinIds,surveyResponseId) {
    let body = {};
    body.campaignId = Number(process.env.GCS_IO_CAMPAIGN_ID);
    body.locale = getFormattedLocale(locale);
    body.surveyId = Number(surveyId);
    body.surveyResponseId = surveyResponseId;
    body.orderId = orderId;
    const surveyResponse = Array.isArray(selectedOptinIds) && selectedOptinIds.map(optionId => ({questionId: Number(sourceQuestionId), optionId: Number(optionId)}))
    body.surveyResponse = [];
    body.surveyResponse.push(...surveyResponse);
    return body;
}

export function getFormattedLocale(locale) {
    let baseLocale = (typeof locale !== 'undefined') ? locale : process.env.GCS_GET_CAMPAIGN_LOCALE;
    if (!baseLocale) {
        return '';
    }
    let language = baseLocale.split('-')[0];
    let country = baseLocale.split('-')[1];
    return language + '-' + country.toUpperCase();
}