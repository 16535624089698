import {
    SpotlightWaitlistConstants
   } from '../View/exportComponent';
import { customJsonstringify } from '../../../adapters/helpers/Utils';

const mixtureParticipationAPI = process.env.MIXTURE_CHAMPAIGN_PARTICIPATION;
const mixtureChannelId = process.env.MIXTURE_CHANNEL_ID;
const mixtureCampaignId = process.env.MIXTURE_CHAMPAIGN_ID;
const mixtureLocale = process.env.MIXTURE_CAMPAIGN_LOCALE;
const mixtureTraitIdValue = process.env.MIXTURE_TRAIT_ID_VALUE;
const mixtureTraitSource = process.env.MIXTURE_TRAIT_SOURCE;
const mixtureOptinIdEmail = process.env.MIXTURE_OPTIN_ID_EMAIL;
const mixtureOptinIdWhatsAPP = process.env.MIXTURE_OPTIN_ID_WHATSAPP;
const mixtureOptinIdTermsAndConditions = process.env.MIXTURE_OPTIN_ID_TERMS_CONDITIONS;
const mixtureUpdateTraitAPI = process.env.MIXTURE_UPDATE_TRAIT_API;
const mixtureTraitcampaignNameId = process.env.MIXTURE_TRAIT_CAMPAIGN_NAME;
const mixtureBrandId = process.env.MIXTURE_BRAND_ID;

function handleOptinId(optinsData, mixtureOptinId) {
    let optinId = mixtureOptinId;
    let optinObj = optinsData && optinsData.filter((item) => item.optIn.id === String(mixtureOptinId));
    if(optinObj && optinObj.length > 0) {
        optinId = optinObj?.[0]?.optIn?.id;
    }
    return optinId;
}

function handleTraitsId(traitsData, mixtureTraitValue, mixtureTraitName) {
    let traitValue = mixtureTraitValue;
    let traitsObj = traitsData && traitsData.filter((item) => item.trait.id === String(mixtureTraitValue));
   
    if(traitsObj && traitsObj.length > 0) {
        traitValue = traitsObj?.[0]?.trait?.id;
    } else {
        traitsObj = traitsData && traitsData.filter((item) => item.trait.name === mixtureTraitName);
        if(traitsObj && traitsObj.length > 0) {
            traitValue = traitsObj?.[0]?.trait?.id;
        }
    }
    
    return traitValue;
}

function removeTags(htmlContent) {
   return htmlContent?.replace( /(<([^>]+)>)/ig, '') || '';
}

function mixtureFormUpdateTraitsPayload(traitsValue) {
    const body = {};
    body['traits'] = [
        {
            "traitId": mixtureTraitIdValue,
            "value": (traitsValue.length > 0) ? traitsValue : ['']
        }
    ];
    return body;
}

function mixtureFormRegisterPayload(email, optinsData, traitsData, termsAndConditionsText, emailMarketText, traitsSourceValue,  traitCampaignID,  traitBrandID, termsCheckStatus, emailMarketOptInStatus, firstName, displayName, whatsAppMarketingText, whatsAppMarketOptInStatus, phoneNumber) {
   
    let body = {};
    body.campaign = {
        'campaignId': Number(mixtureCampaignId),
        'locale': mixtureLocale
    };

    body.consumer = {
        'email': email
    };
    body.traits = [
        {
            'traitId': handleTraitsId(traitsData, mixtureTraitcampaignNameId, SpotlightWaitlistConstants.transmitterSource),
            'value': [traitsSourceValue]
        },
        {
            'traitId': handleTraitsId(traitsData, mixtureTraitSource, SpotlightWaitlistConstants.traitCampaignNameIdValue),
            'value': [traitCampaignID]
        },
        {
            'traitId': handleTraitsId(traitsData, mixtureBrandId, SpotlightWaitlistConstants.Brand),
            'value': [traitBrandID]
        }
    ];
    
    if(displayName) {
        let socialNameTrait = {
            'traitId': SpotlightWaitlistConstants.displayTraitId,
            'value': [displayName]
        };
        body.traits.push(socialNameTrait)
    }
    if(phoneNumber) {
        let phoneNumberTrait = {
            'traitId': SpotlightWaitlistConstants.phoneNumberTraitID,
            'value': [phoneNumber]
        };
        body.traits.push(phoneNumberTrait)
    }
    
    body.optIns = [];

    if (termsCheckStatus) {
        body.optIns.push({
           'optinId': handleOptinId(optinsData, mixtureOptinIdTermsAndConditions), 
            'optinText': removeTags(termsAndConditionsText)
        });
    }
    
    if (emailMarketOptInStatus) {
        body.optIns.push({
            'optinId': handleOptinId(optinsData, mixtureOptinIdEmail),
            'optinText': removeTags(emailMarketText)
        });
    }   

    if (whatsAppMarketOptInStatus) {
        body.optIns.push({
            'optinId': handleOptinId(optinsData, mixtureOptinIdWhatsAPP),
            'optinText': removeTags(whatsAppMarketingText)
        });
    }  

    if(firstName) {
        body.consumer['firstName'] = firstName
    }

    return body;
}

export const mixtureFormRegister = async ({email, optinsData, traitsData, termsAndConditionsText, emailMarketText, traitsSourceValue,  traitCampaignID,  traitBrandID, termsCheckStatus, emailMarketOptInStatus, firstName, displayName, whatsAppMarketingText, whatsAppMarketOptInStatus, phoneNumber }) => {
    const body = mixtureFormRegisterPayload(email, optinsData, traitsData, termsAndConditionsText, emailMarketText, traitsSourceValue,  traitCampaignID, traitBrandID, termsCheckStatus, emailMarketOptInStatus, firstName, displayName, whatsAppMarketingText, whatsAppMarketOptInStatus, phoneNumber );
    try {
        
        const response = await fetch(mixtureParticipationAPI, {
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "channelId": mixtureChannelId
            },
            body: customJsonstringify(body)
          });
          
            if(!response.ok) {
                return response;
            }
        const data = await response.json();
        return data;        
    } catch (error) {
        throw true;
    }    
} 

export const updateTraitsMixtureFormRegister = async ({traitsValue, participationID}) => {
    try {
        const body = mixtureFormUpdateTraitsPayload(traitsValue);
        const url = mixtureUpdateTraitAPI + '/' + participationID + '/traits'
        const response = await fetch(url , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "channelId": mixtureChannelId
            },
            body: customJsonstringify(body)
          });
        if(!response.ok) {
            return response;
        }
        const data = await response.json();
        return data;        
    } catch (error) {
        throw error;
    }    

}
